/**
 * Messages это все сообщения в системе.
 * Если нужно показать сообщения для списка тем, то можно определить { theme: { list: {...} }
 * А в коде, это будет работать так: <message type="theme:list" />
 *
 * Существует несколько атрибутов сообщения:
 * 1) title - заголовок,
 * 2) color - цвет сообщения (danger, warning, success, primary, и тд)
 * 3) description: описание сообщения (не всегда используется)
 * 4) close - можно ли закрыть сообщение
 *
 * Определяется в $store.messages
 */

export const Messages = {
  auth: {
    signin: {
      authorized: {
        color: "primary",
        title: "Вы уже вошли в систему!",
        description:
          "Если вы хотите сменить аккаунт, необходимо выйти из аккаунта.",
      },
      incorrect: {
        color: "danger",
        title: "Попробуйте еще раз!",
        description: "Логин или пароль не совпадают.",
      },
      token: {
        color: "danger",
        title: "Данные авторизации устарели.",
        description: "Необходим повторный ввод пароля.",
      },
      next: {
        color: "primary",
        title: "Чтобы продолжить, необходимо войти в аккаунт.",
      },
      rejected: {
        color: "danger",
        title: "Ошибка загрузки данных!",
        description: "Попробуйте еще раз...",
      },
    },
    signup: {
      username: {
        color: "warning",
        title: "Вы не можете использовать указанный логин для регистрации!",
        description: "Попробуйте другое значение.",
      },
      email: {
        color: "warning",
        title: "Email который, вы указали уже используется!",
        description: "Вы можете войти в аккаунт, используя логин и пароль.",
      },
      rejected: {
        color: "danger",
        title: "Ошибка загрузки данных!",
      },
      registered: {
        color: "success",
        title: "Аккаунт зарегистрирован!",
        description:
          "Авторизуем вас в системе... Это происходит автоматически.",
      },
    },
    reset: {
      sended: {
        color: "success",
        title: "Ссылка для сброса пароля отправлена.",
        desciption: "Проверьте почтовый ящик! Ссылка действительна один день.",
      },
      success: {
        color: "success",
        title: "Пароль изменен!",
        description:
          "Теперь вы можете зайти в аккаунт, используя новый пароль.",
      },
      rejected: {
        color: "danger",
        title: "Возникла ошибка.",
        description: "Попробуйте еще раз!",
      },
    },
  },

  verify: {
    loading: {
      color: "primary",
      title: "Получаем данные...",
    },
    success: {
      color: "success",
      title: "Email подтвержден!",
      description: "Вы успешно подтвердили ваш email.",
    },
    rejected: {
      color: "danger",
      title: "Произошла ошибка!",
      description: "Мы не смогли подтвердить ваш email. Попробуйте еще раз...",
    },
  },

  account: {
    profile: {
      success: {
        color: "success",
        title: "Информация обновлена!",
        close: true,
      },
      rejected: {
        color: "danger",
        title: "Ошибка сохраенния данных!",
        description: "Попробуйте еще раз...",
      },
    },
    password: {
      success: {
        color: "success",
        title: "Пароль изменен успешно!",
        description:
          "При следующем входе в аккаунт, вы должны использовать новый пароль.",
        close: true,
      },
      incorrect: {
        color: "warning",
        title: "Вы не можете изменить пароль!",
        description: "Вы указали неправильный пароль. Попробуйте еще раз...",
      },
      rejected: {
        color: "danger",
        title: "Ошибка изменения данных!",
        description: "Попробуйте еще раз...",
      },
    },
  },

  theme: {
    list: {
      loading: {
        color: "primary",
        title: "Загружаем данные...",
      },
      rejected: {
        color: "danger",
        title: "Ошибка загрузки данных!",
      },
      empty: {
        color: "warning",
        title: "Ничего нет!",
        description:
          "Вы можете добавить новую тему, нажав на соответствующую кнопку.",
      },
      deleted: {
        color: "success",
        title: "Тема удалена",
        close: true,
      },
    },
    form: {
      loading: {
        color: "primary",
        title: "Загружаем данные...",
      },
      check: {
        color: "danger",
        title: "Не удалось сохранить форму!",
        description: "Кажется вы забыли указать некоторые данные.",
      },
      created: {
        color: "success",
        title: "Тема создана!",
        description:
          "Теперь вы можете использовать данную тему при создании тестирований.",
        close: true,
      },
      updated: {
        color: "success",
        title: "Тема обновлена!",
        description:
          'Новые вопросы автоматически не добавляются к тесту. Это можно сделать в разделе "Вопросы" для конкретного теста.',
        close: true,
      },
      rejected: {
        color: "danger",
        title: "Ошибка загрузки данных!",
      },
    },
  },

  test: {
    list: {
      loading: {
        color: "primary",
        title: "Загружаем данные...",
      },
      rejected: {
        color: "danger",
        title: "Ошибка загрузки данных!",
      },
      empty: {
        student: {
          color: "warning",
          title: "Ничего нет!",
          description:
            "Как только для вас будут доступны тесты, они будут показаны на этой странице! Попробуйте зайти в другой раз...",
        },
        other: {
          color: "warning",
          title: "Ничего нет!",
          description:
            "Вы можете добавить новое тестирования, нажав на соответствующую кнопку.",
        },
      },
      deleted: {
        color: "success",
        title: "Тестирование удалено!",
        close: true,
      },
    },
    general: {
      saved: {
        color: "success",
        title: "Тестирование сохранено!",
        description:
          'Теперь вы можете перейти на вкладку "Вопросы", чтобы удалить или добавить вопросы к тестированию.',
        close: true,
      },
      slug: {
        color: "danger",
        title: "Некорректная ссылка для просмотра!",
        description:
          "Значение уже используется для другого теста в системе. Попробуйте другое значение.",
      },
      rejected: {
        color: "danger",
        title: "Ошибка сохранения данных!",
        description: "Попробуйте еще раз.",
      },
      themes: {
        rejected: {
          color: "danger",
          title: "Ошибка загрузки данных!",
          description:
            "Мы не смогли получить список тем, чтобы показать в списке. Попробуйте закрыть и снова открыть данное окно.",
        },
      },
      groups: {
        rejected: {
          color: "danger",
          title: "Ошибка загрузки данных!",
          description:
            "Мы не смогли получить список групп, чтобы показать в списке. Попробуйте закрыть и снова открыть данное окно.",
        },
      },
    },
    questions: {
      updated: {
        color: "success",
        title: "Список активных вопросов обновлен",
        close: true,
      },
      empty: {
        color: "warning",
        title: "Контейнер вопросов пуст!",
        description:
          'Вопросы автоматически мигрируют из выбранных тем в тестирования. Поэтому, чтобы добавить вопросы к тестированию, необходимо сделать это на странице "Темы"',
      },
      rejected: {
        get: {
          color: "danger",
          title: "Ошибка получения данных!",
          description: "Попробуйте еще раз.",
        },
        set: {
          color: "danger",
          title: "Ошибка сохранения данных!",
          description: "Попробуйте еще раз.",
        },
      },
    },
    settings: {
      rejected: {
        deleted: {
          color: "danger",
          title: "Ошибка удаления!",
          description: "Попробуйте еще раз.",
        },
      },
    },
  },

  group: {
    loading: {
      color: "primary",
      title: "Загружаем данные",
    },
    empty: {
      color: "warning",
      title: "Ничего нет!",
      description:
        "Вы можете создать новую группу, нажав на соответствующую кнопку.",
    },
    deleted: {
      color: "success",
      title: "Группа удалена!",
      close: true,
    },
    rejected: {
      color: "danger",
      title: "Ошибка загрузки данных!",
    },
    view: {
      loading: {
        color: "primary",
        title: "Загружаем данные",
      },
      created: {
        color: "success",
        title: "Группа добавлена!",
        close: true,
      },
      updated: {
        color: "success",
        title: "Группа обновлена!",
        close: true,
      },
      rejected: {
        color: "danger",
        title: "Ошибка загрузки данных!",
      },
    },
  },

  passage: {
    loading: {
      color: "primary",
      title: "Загружаем данные...",
    },
    rejected: {
      color: "danger",
      title: "Ошибка загрузки данных!",
    },
    created: {
      color: "success",
      title: "Тестирование началось!",
      description:
        "По истечению времени результат будет автоматически сохранен.",
      close: true,
    },
    saved: {
      color: "success",
      title: "Ваши ответы сохранены!",
      description: "Теперь вы можете покинуть данную страницу.",
    },
    completed: {
      color: "success",
      title: "Тест пройден!",
      description:
        "Преподаватель проверит ваши ответы и вы сможете увидеть результат в личном кабинете.",
    },
    completedAnonymous: {
      color: "success",
      title: "Тест пройден!",
      description: "Результат будет сохранен в системе.",
    },
    questions: {
      color: "danger",
      title: "Нет возможности начать или продолжить тестирование!",
      description:
        "Для данного тестирования не добавлены вопросы. Как только преподаватель исправит данную ситуацию, вы сможете пройти тестирование.",
    },
    period: {
      past: {
        color: "warning",
        title: "Пройти тестирование нельзя!",
        description: "Срок прохождения данного тестирования завершен!",
      },
      future: {
        color: "warning",
        title: "Пройти тестирование нельзя!",
        description: "Преподаватель указал, что тестирование начнется позже!",
      },
    },
    denied: {
      color: "danger",
      title: "Нет доступа для прохождения тестирования!",
      description:
        "Тест может пройти только тот, кто состоит в группе, для которой был создан данный тест.",
    },
  },

  result: {
    list: {
      loading: {
        color: "primary",
        title: "Загружаем данные...",
      },
      empty: {
        color: "warning",
        title: "Ничего нет!",
        description:
          "Как только кто-либо из студентов начнет прохождение данного тестирования, результат появится в списке.",
      },
      rejected: {
        color: "danger",
        title: "Ошибка загрузки данных!",
      },
    },
    passage: {
      loading: {
        color: "primary",
        title: "Загружаем данные...",
      },
      rejected: {
        color: "danger",
        title: "Ошибка загрузки данных!",
      },
    },
  },

  users: {
    list: {
      loading: {
        color: "primary",
        title: "Загружаем данные...",
      },
      search: {
        color: "warning",
        title: "По вашему запросу ничего не найдено!",
      },
      empty: {
        color: "warning",
        title: "В системе нет пользователей с данным статусом!",
      },
      status: {
        color: "success",
        title: "Статус пользователя изменен!",
        close: true,
      },
      rejected: {
        color: "danger",
        title: "Ошибка загрузки данных!",
      },
    },
    modal: {
      created: {
        color: "success",
        title: "Пользователь создан!",
        desciption: "Обновите страницу, чтобы увидеть в списке.",
      },
      errors: {
        color: "danger",
        title: "Проверьте правильность ввода данных!",
      },
      rejected: {
        color: "danger",
        title: "Ошибка сохранения данных!",
      },
    },
  },

  notifications: {
    loading: {
      color: "primary",
      title: "Загружаем данные...",
    },
    empty: {
      color: "warning",
      title: "Для вас еще нет активных уведомлений!",
      description: "Как только что-нибудь появится, мы вам сообщим.",
    },
    rejected: {
      color: "danger",
      title: "Ошибка загрузки данных!",
    },
  },
};
