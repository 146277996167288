import Vue from 'vue'
import Vuex from 'vuex'
import { Messages } from './messages'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /**
     * Объект, хранящий все данные о пользователе. Например почта, логин и многое другое
     */
    account: {},
    /**
     * Авторизован ли? Если true то юзер авторизован, показываем интерфейс
     * Если не авторизован показываем в итоге страницу входа в аккаунт
     */
    authorized: false,
     /**
     * Все ли уведомления прочитаны?
     * Хранит количество непрочитанных уведомлений в системе
     */
    notifications: false,
    /**
     * Подтвержден ли аккаунт?
     * Если true значит данный пользователь подтвердил аккаунт, перейдя по ссылке
     * В противном случае многие функции не работают
     */
    verified: false,
    /**
     * Хранилище всех сообщений в системе. Типы сообщений прописаны в файле ./messages.js. 
     * Экшены show/hide Message отвечают за работу уведомлений в системе
     */
    messages: [],
    /**
     * Объект текущего открытого тестирования в модальном окне. 
     * Если открыта форма создания, то объект пуст
     */
    modalTesting: {},

    themes: [],
    groups: []
  },
  getters: {
    testing: function (state) {
      let now = new Date()

      let active = []
      let future = []
      let past = []

      state.testing.forEach(item => {
        let date_start = new Date(item.date_start)
        let date_end = new Date(item.date_end)

        if (date_start > now) future.push(item)
        else if (date_end < now) past.push(item)
        else active.push(item)
      })

      return {
        active: active,
        future: future,
        past: past
      }
    }
  },
  mutations: {
    setUser: function (state, payload) {
      state.account = payload
      state.authorized = true
      state.verified = payload.verified
      state.notifications = payload.notifications
    },
    clearNotifications: function (state) {
      state.notifications = false
    },
    toggleVerified: function (state) {
      state.verified = !state.verified
    },
    removeUser: function (state) {
      state.user = {}
      state.authorized = false
    },
    setMessage: function (state, type) {
      let computed = null

      // получем класс и подкатегории
      type.split(':').forEach(sub => {
        if (!computed) computed = Messages[sub]
        else computed = computed[sub]
      })

      // блокируем показ еще сообщений если уже показано 
      if (computed && !state.messages.filter(message => message.type == type).length) {
        let message = computed
        message.type = type

        state.messages.unshift(message)
      }
    },
    popMessage: function (state, type) {
      state.messages = state.messages.filter(message => !message.type.includes(type))
    },
    setTesting: function (state, testing) {
      state.testing = testing
    },
    pushTesting: function (state, testing) {
      state.modalTesting.id = testing.id
      state.modalTesting.hash = testing.hash

      state.testing.unshift(state.modalTesting)
    },
    setThemes: function (state, themes) {
      state.themes = themes
    },
    setGroups: function (state, groups) {
      state.groups = groups
    },
  },
  actions: {
    /**
     * Сохраняем текущиего пользователя и токен для него в localStorage.
     * После обновления страницы получаем токен и делаем запрос на сервер. 
     * То есть данная функция выполняется всякий раз после обновления страницы или при авторизации через форму
     */
    authorize: function (context, payload) {
      localStorage.setItem('token', payload.token)
      localStorage.setItem('account', JSON.stringify(
        (({ username, permission }) => ({ username, permission }))(payload.account)
      ))

      context.commit('setUser', payload.account)
    },
    /**
     * Вызываем смену параметра verified если почта изменена или подтвердили почту
     */
    setVerified: function (context) {
      context.commit('toggleVerified')
    },
    /**
     * Очищаем текущего пользователя
     * Удаляем на сервере токен
     */
    logout: function (context) {
      localStorage.removeItem('token')
      localStorage.removeItem('account')
      context.commit('removeUser')
    },
    /**
     * Получаем класс сообщения и подгруппы. Например в примере <test:list>, <test> это класс сообщения, а <list> это группа. 
     * Работает как обычный объект. 
     * 
     * Получить все сообщения одного типа можно указав <message type="test:list" /> в шаблоне. Указав <message type="test" получим все сообщения класса test.
     */
    showMessage: function (context, type) {
      context.commit('setMessage', type)
    },
    /**
     * Удаляет класс или подгруппу сообщений в зависимости от переданного типа. Если state.dispatch(..., 'test') то будут удалены все сообщения класса test.
     * Если передать test:list то это удалит только одно сообщение. 
     * Поскольку <message /> тег слушает все что происходит тут, сообщения сразу же удалятся со старницы
     */
    hideMessage: function (context, type) {
      context.commit('popMessage', type)
    },
    saveThemes: function (context, themes) {
      context.commit('setThemes', themes)
    },
    saveGroups: function (context, groups) {
      context.commit('setGroups', groups)
    },

    /**
     * Прочитываем уведомление, помечаем, что мы его прочитали
     */
    watchNotifications: function (context) {
      context.commit('clearNotifications')
    }
  }
})
