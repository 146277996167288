import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },

  // Account routes
  {
    path: '/account',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/user/Profile.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/views/user/Settings.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('@/views/user/Notifications.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },

  // Theme Routes
  {
    path: '/themes',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: 'Themes',
        component: () => import('@/views/testing/Themes.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['teacher', 'admin']
        },
      },
      {
        path: 'create',
        name: 'ThemeCreate',
        component: () => import('@/views/testing/Theme.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['teacher', 'admin']
        }
      },
      {
        path: ':hash',
        name: 'Theme',
        component: () => import('@/views/testing/Theme.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['teacher', 'admin']
        }
      },
    ]
  },

  // Group Routes
  {
    path: '/groups',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: 'Groups',
        component: () => import('@/views/testing/Groups.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['teacher', 'admin']
        }
      },
      {
        path: 'create',
        name: 'GroupCreate',
        component: () => import('@/views/testing/Group.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['teacher', 'admin']
        }
      },
      {
        path: ':hash',
        name: 'Group',
        component: () => import('@/views/testing/Group.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['teacher', 'admin']
        }
      },
    ]
  },

  // Test Routes
  {
    path: '/tests',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: 'Tests',
        component: () => import('@/views/testing/Tests.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'create',
        name: 'TestCreate',
        component: () => import('@/views/testing/Test.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ':hash',
        name: 'Test',
        component: () => import('@/views/testing/Test.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },

  // Passage Route
  {
    path: '/passage/:hash',
    name: 'Passage',
    component: () => import('@/views/testing/Passage.vue')
  },

  // Result Routes
  {
    path: '/results',
    component: { render: h => h('router-view') },
    children: [
      {
        path: ':test',
        name: 'Results',
        component: () => import('@/views/testing/Results.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['teacher', 'admin']
        }
      },
      {
        path: ':test/:passage',
        name: 'Result',
        component: () => import('@/views/testing/Result.vue'),
        meta: {
          requiresAuth: true
        }
      },
    ]
  },

  // Admin pages
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/user/Users.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['admin', 'teacher']
    }
  },

  // Faq Routes
  {
    path: '/faq/',
    name: 'FAQ',
    component: () => import('@/views/FAQ.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: ':subpage',
        name: 'FAQSubpage',
        component: () => import('@/views/FAQ.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },

  // Auth Routes
  {
    path: '/auth',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'signin/',
        name: 'Signin',
        component: () => import('@/views/auth/Signin.vue'),
        meta: {
          hiddedAuthorized: true,
        }
      },
      {
        path: 'signup',
        name: 'Signup',
        component: () => import('@/views/auth/Signup.vue'),
        meta: {
          hiddedAuthorized: true,
        }
      },
      {
        path: 'verify/:hash',
        name: 'Verify',
        component: () => import('@/views/auth/Verify.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'reset/:token?',
        name: 'Reset',
        component: () => import('@/views/auth/Reset.vue'),
        meta: {
          hiddedAuthorized: true,
        }
      },
    ]
  },

  // Error Routes
  {
    name: '403',
    path: '/unallowed',
    component: () => import('@/views/errors/403.vue'),
    meta: {
      requiresAuth: true,
      hiddenSubmited: true,
    }
  },
  {
    name: '404',
    path: '*',
    component: () => import('@/views/errors/404.vue'),
    meta: {
      requiresAuth: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => ({ x: 0, y: 0 }),
  routes
})

// check router
router.beforeEach((to, from, next) => {
  // check authorize
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.authorized) {
      next({
        name: 'Signin', 
        query: {
          redirect: to.fullPath
        }
      })

      return
    } else {
      if (store.state.account.status != 'submited' && to.name != '403') {
        next({
          name: '403'
        })
      } else next()
    }
  } else next()

  // check permissions
  if (to.matched.some(record => record.meta.permissions)) {
    if (!to.meta.permissions.includes(store.state.account.permission)) {
      next({
        name: '404'
      })

      return
    }
  } else next()

  // check hidden authorize
  if (to.matched.some(record => record.meta.hiddedAuthorized)) {
    if (store.state.authorized) {
      next({
        name: 'Index'
      })
    }
  } else next()
})

router.onError(error =>{
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload()
  }
});

export default router
