<template>
  <div id="app" class="border-top-wide border-primary">
    <div class="page">
      <!-- Main Header -->
      <div id="header" v-show="showNavigation">
        <header class="navbar navbar-light navbar-expand-md">
          <div class="container-xl">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="d-flex align-items-center">
              <a href="#" class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal" style="margin-right: 5px">
                EduTest
              </a>

              <div class="d-flex gap-2">
                <badge :permission="permission" />
              </div>
            </div>

            <div class="navbar-nav flex-row order-md-last">
              <div class="nav-item dropdown me-3 d-none d-sm-flex">
                <router-link :to="{ name: 'Notifications' }" class="nav-link px-0 text-reset">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                    <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                  </svg>
                  <span class="badge bg-red" v-if="notifications"></span>
                </router-link>
              </div>

              <div class="nav-item dropdown">
                <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown">
                  <div class="avatar bg-azure-lt">{{ username.split(' ').map(word => word[0]).join('').slice(0, 2) }}
                  </div>

                  <div class="d-none d-xl-block ps-2">
                    <div>{{ username }}</div>
                    <div class="mt-1 small text-muted">Управление</div>
                  </div>
                </a>

                <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <router-link class="dropdown-item d-block" :to="{ name: 'Profile' }">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24"
                      height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                    </svg>
                    Профиль
                  </router-link>

                  <router-link :to="{ name: 'Notifications' }" class="dropdown-item d-block d-sm-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                      stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6">
                      </path>
                      <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                    </svg>
                    Уведомления
                    <span class="badge bg-red" v-if="notifications"></span>
                  </router-link>

                  <router-link class="dropdown-item d-block" :to="{ name: 'Settings' }">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-settings" width="24"
                      height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z">
                      </path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                    Настройки
                  </router-link>

                  <div class="dropdown-divider"></div>

                  <div class="dropdown-item cursor-pointer d-block" @click="logout">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-logout" width="24"
                      height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
                      <path d="M7 12h14l-3 -3m0 6l3 -3"></path>
                    </svg>
                    Выйти
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div class="navbar-expand-md">
          <div class="collapse navbar-collapse" id="navbar-menu">
            <div class="navbar navbar-light">
              <div class="container-xl">
                <div class="navbar-nav align">
                  <router-link class="nav-item nav-link" :to="{ name: 'Index' }" exact>
                    <span class="nav-link-title">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                        <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                      </svg>
                      Главная
                    </span>
                  </router-link>

                  <router-link class="nav-item nav-link" :to="{ name: 'Users' }" v-if="permission != 'student'">
                    <span class="nav-link-title">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="9" cy="7" r="4" />
                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                        <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>
                      {{ permission == 'admin' ? 'Пользователи' : 'Ученики' }}
                    </span>
                  </router-link>

                  <router-link class="nav-item nav-link" :to="{ name: 'Themes' }" v-if="permission != 'student'">
                    <span class="nav-link-title">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-text" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                        <line x1="9" y1="9" x2="10" y2="9" />
                        <line x1="9" y1="13" x2="15" y2="13" />
                        <line x1="9" y1="17" x2="15" y2="17" />
                      </svg>
                      Темы
                    </span>
                  </router-link>

                  <router-link class="nav-item nav-link" :to="{ name: 'Groups' }" v-if="permission != 'student'">
                    <span class="nav-link-title">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 4h3l2 2h5a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                        <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2" />
                      </svg>
                      Группы
                    </span>
                  </router-link>

                  <router-link class="nav-item nav-link" :to="{ name: 'Tests' }">
                    <span class="nav-link-title">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                        <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                        <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                      </svg>
                      Тесты
                    </span>
                  </router-link>

                  <a class="nav-item nav-link disabled">
                    <span class="nav-link-title">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-report-analytics" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                        <rect x="9" y="3" width="6" height="4" rx="2"></rect>
                        <path d="M9 17v-5"></path>
                        <path d="M12 17v-1"></path>
                        <path d="M15 17v-3"></path>
                      </svg>
                      Статистика
                    </span>
                  </a>
                </div>

                <div class="navbar-nav order-md-last">
                  <router-link class="nav-item nav-link" :to="{ name: 'FAQ' }">
                    <span class="nav-link-title">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="10" cy="10" r="7" />
                        <path d="M21 21l-6 -6" />
                        <line x1="10" y1="13" x2="10" y2="13.01" />
                        <path d="M10 10a1.5 1.5 0 1 0 -1.14 -2.474" />
                      </svg>
                      Помощь
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-xl d-flex flex-column">
          <div class="alert alert-warning alert-dismissible bg-white" v-if="showNavigation && !verified">
            <strong>Ваш аккаунт не подтвержден!</strong>
            <p class="mb-0">
              Необходимо подтвердить аккаунт, чтобы разблокировать многие возможности.
              Ссылка для подтверждения была отправлена на вашу электронную почту!
            </p>

            <button class="btn-close" data-bs-dismiss="alert"></button>
          </div>

          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>

      <!-- Main Footer -->
      <footer class="footer footer-transparent mb-0" v-show="showNavigation">
        <div class="container">
          Copyright by <a href="https://github.com/paqstd-team" class="link-secondary">paqstd.team</a>. All rights reserved.
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'App',
    components: {
      Badge: () => import('@/components/user/Badge')
    },
    metaInfo: {
      title: 'Powerful SaaS for online educated',
      titleTemplate: '%s | Edutest'
    },
    computed: {
      showNavigation: function () {
        return !['Signin', 'Signup', 'Verify', 'Passage', '404', '403'].includes(this.$route.name) && this.$store.state.authorized
      },
      verified: function () {
        return this.$store.state.verified
      },
      permission: function () {
        return this.$store.state.account.permission
      },
      username: function () {
        let fio = this.$store.state.account.fio
        return fio ? fio : 'Anonymous'
      },
      notifications: function () {
        return this.$store.state.notifications
      }
    },
    methods: {
      logout: function () {
        this.$store.dispatch('logout')
        this.$router.push({
          name: 'Signin'
        })
      }
    },
    watch: {
      '$route': function () {
        let navbar = document.querySelector('.navbar-collapse')

        if (navbar && navbar.classList.contains('show')) {
          document.querySelector(".navbar-toggler").click();
        }
      }
    }
  }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }

  .nav-link {
    flex-direction: row;
  }
</style>