import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
Vue.component("v-select", vSelect);

// axios
const API_URL = process.env.VUE_APP_API_URL
Vue.prototype.$axios = axios.create({
  baseURL: API_URL ? API_URL : `${document.location.protocol}//${document.location.hostname}/api/`,
});

// autosize
import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VueTextareaAutosize);

// meta
import VueMeta from "vue-meta";
Vue.use(VueMeta);

// vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// sentry
import * as Sentry from "@sentry/vue";

Sentry.init({
  Vue,
  dsn: "https://11aff3552aaf4a3a8d8aff2d819f46e7@o456936.ingest.sentry.io/4505342083465216",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/edutest\.online\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
